import { Fragment, useState, useMemo } from 'react';
import { JsonForms } from '@jsonforms/react';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import logo from './logo.svg';
import './App.css';
import schema from './schema.json';
import uischema from './uischema.json';
import {
  materialCells,
  materialRenderers,
} from '@jsonforms/material-renderers';
import RatingControl from './RatingControl';
import ratingControlTester from './ratingControlTester';
import { makeStyles } from '@mui/styles';
import { Divider, IconButton, Stack, TextField } from '@mui/material';
import { Download } from '@mui/icons-material';

const useStyles = makeStyles({
  container: {
    padding: '1em',
    width: '100%',
  },
  title: {
    textAlign: 'center',
    padding: '0.25em',
  },
  dataContent: {
    display: 'flex',
    justifyContent: 'center',
    borderRadius: '0.25em',
    backgroundColor: '#cecece',
    marginBottom: '1rem',
  },
  resetButton: {
    margin: 'auto !important',
    display: 'block !important',
  },
  demoform: {
    margin: 'auto',
    padding: '1rem',
  },
});

const initialData = {};

const renderers = [
  ...materialRenderers,
  //register custom renderers
  { tester: ratingControlTester, renderer: RatingControl },
];

const App = () => {
  const classes = useStyles();
  const [data, setData] = useState<any>(initialData);
  const stringifiedData = useMemo(() => JSON.stringify(data, null, 2), [data]);
  const [importData, setImportData] = useState<string>('');
  const downloadFile = () => {
    const myData = data; // I am assuming that "this.state.myData"
    // is an object and I wrote it to file as
    // json

    // create file in browser
    const fileName = 'exportJSON-Twelve';
    const json = JSON.stringify(myData, null, 2);
    const blob = new Blob([json], { type: 'application/json' });
    const href = URL.createObjectURL(blob);

    // create "a" HTLM element with href to file
    const link = document.createElement('a');
    link.href = href;
    link.download = fileName + '.json';
    document.body.appendChild(link);
    link.click();

    // clean up "a" element & remove ObjectURL
    document.body.removeChild(link);
    URL.revokeObjectURL(href);
  };

  const clearData = () => {
    setData({});
  };

  return (
    <Fragment>
      <Grid
        container
        justifyContent={'center'}
        spacing={1}
        className={classes.container}
      >
        <Grid item sm={6}>
          <Stack
            direction='row'
            spacing={2}
            alignItems='center'
            width='100%'
            justifyContent='center'
          >
            <Typography variant={'h4'} className={classes.title}>
              Data générées
            </Typography>
            <Button
              startIcon={<Download />}
              onClick={downloadFile}
              variant={'contained'}
            >
              Hyper vitesse
            </Button>
          </Stack>
          <div style={{ overflow: 'scroll' }}>
            <pre id='boundData'>{stringifiedData}</pre>
          </div>
          <Button
            className={classes.resetButton}
            onClick={clearData}
            color='primary'
            variant='contained'
          >
            Clear data
          </Button>
          <Divider variant='fullWidth' sx={{ my: 2 }} />
          <Stack direction='column' sx={{ mt: 3 }}>
            <Typography variant='h6'>Importer des data</Typography>
            <TextField
              multiline
              rows={10}
              value={importData}
              onChange={(e) => setImportData(e.target.value)}
            />
            <Button onClick={() => setData(JSON.parse(importData))}>
              IMPORT
            </Button>
          </Stack>
        </Grid>
        <Grid item sm={6}>
          <Typography variant={'h4'} className={classes.title}>
            EDITEUR
          </Typography>
          <div className={classes.demoform}>
            <JsonForms
              schema={schema}
              uischema={uischema}
              data={data}
              renderers={renderers}
              cells={materialCells}
              onChange={({ errors, data }) => setData(data)}
            />
          </div>
        </Grid>
      </Grid>
    </Fragment>
  );
};

export default App;
